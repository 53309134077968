import React from 'react';
import './Button.scss';
import classNames from 'classnames';
import Icons from '../Icons/Icons';
import Spinner from '../Spinner/Spinner';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	buttonType?: 'primary' | 'without-arrow' | 'without-arrow-ghost' | 'large' | 'small' | 'ghost' | string;
	additionalClass?: string;
	isLoading?: boolean;
	isDisabled?: boolean;
	children: React.ReactNode;
}

function Button({ buttonType = 'primary', additionalClass, isLoading, isDisabled, children, ...props }: ButtonProps) {
	return (
		<button
			className={classNames([
				'btn',
				buttonType && `btn--${buttonType}`,
				isDisabled && 'is-disabled',
				isLoading && 'is-loading',
				additionalClass,
			])}
			{...props}
		>
			<span className="btn__text">
				{children} {buttonType !== 'without-arrow' && buttonType !== 'without-arrow-ghost' && <Icons modifier="arrow-right" />}
			</span>
			{isLoading && <Spinner />}
		</button>
	);
}

export default React.memo(Button);
