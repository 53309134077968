import classNames from 'classnames';
import React from 'react';
import './Checkbox.scss';
import { UseFormRegister } from 'react-hook-form';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
	modifier?: 'without-label' | 'reverse';
	name: string;
	register: UseFormRegister<any>;
	errors: any;
	validationSchema?: any;
	label: string | undefined | null;
}

function Checkbox({ name, label, modifier, register, errors, validationSchema, ...props }: CheckboxProps) {
	return (
		<div className={classNames(['checkbox', errors[name] && 'is-validation-failed', modifier && `checkbox--${modifier}`])}>
			<input type="checkbox" {...register(name, validationSchema)} {...props} />
			<label className="checkbox__label" htmlFor={props.id}>
				<span className="checkbox__text" dangerouslySetInnerHTML={{ __html: label || '' }} />
			</label>
		</div>
	);
}

export default React.memo(Checkbox);
