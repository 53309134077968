import React from 'react';
import classNames from 'classnames';
import './Radio.scss';
import { UseFormRegister } from 'react-hook-form';
import Icons from '@atoms/Icons/Icons';

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
	modifier?: 'reverse' | 'star' | 'star-hover' | 'red';
	name: string;
	register: UseFormRegister<any>;
	errors?: any;
	validationSchema?: any;
	label: string | undefined | null;
	onMouseEnter?: any;
	onMouseLeave?: any;
	additionalClass?: string;
}

function Radio({
	name,
	label,
	modifier,
	register,
	errors,
	validationSchema,
	onMouseEnter,
	onMouseLeave,
	additionalClass,
	...props
}: RadioProps) {
	return (
		<div
			className={classNames(['radio', errors[name] && 'is-validation-failed', modifier && `radio--${modifier}`, additionalClass])}
			onMouseEnter={onMouseEnter && (() => onMouseEnter())}
			onMouseLeave={onMouseLeave && (() => onMouseLeave())}
		>
			<input type="radio" {...register(name, validationSchema)} {...props} />
			<label
				htmlFor={props.id}
				className={classNames([
					modifier !== 'star' && 'radio__label',
					modifier === 'red' ? 'radio__label--red' : 'radio__label--normal',
				])}
			>
				{modifier !== 'star' && <span className="radio__text" dangerouslySetInnerHTML={{ __html: label || '' }} />}
				<span className="radio__star-icon">{modifier === 'star' && <Icons modifier="star" />}</span>
			</label>
		</div>
	);
}

export default React.memo(Radio);
