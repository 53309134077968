import React, { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './FormError.scss';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';

interface FormErrorProps {
	isError?: boolean;
	children?: React.ReactNode;
	httpError?: AxiosError;
	gqError?: ApolloError;
}

function FormError({ isError, httpError, gqError, children }: FormErrorProps) {
	const { t } = useTranslation();
	const error = useMemo(() => {
		if (httpError) {
			if (httpError?.response?.status === 500) {
				return t('general.error');
			}

			const data = httpError?.response?.data as any;
			return data?.records?.message || data?.message || httpError?.message;
		}

		if (gqError) {
			return gqError?.message;
		}

		return children;
	}, [children, gqError, httpError, t]);

	return (
		<AnimatePresence>
			{(!!httpError || gqError || isError) && (
				<motion.div
					animate={{ height: 'auto', overflow: 'visible' }}
					className="form-error"
					exit={{ height: 0, overflow: 'hidden' }}
					initial={{ height: 0, overflow: 'hidden' }}
					transition={{ duration: 0.3 }}
				>
					<motion.div animate={{ opacity: 1 }} className="form-error__inner" initial={{ opacity: 0 }} transition={{ delay: 0.2 }}>
						{error}
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}

export default React.memo(FormError);
