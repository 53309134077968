import { z } from 'zod';
import { surveyQuestions } from '@core/survey/survey-data';
import { SurveyFormValues } from '@molecules/SurveyModal/SurveyModal';
import { UseFormSetError } from 'react-hook-form';

export const passwordValidation = z
	.string()
	.min(8)
	.regex(
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?!.*\s).{8,}$/,
		'Geslo mora vsebovati vsaj eno veliko črko, eno malo črko, eno številko in en poseben znak.',
	);

export const isCASFormat = (str: string) => {
	const regex = /^CAS-\d{5}-[A-Z0-9]{6}$/;
	return regex.test(str);
};

export const validateQuestionInput = (surveyValues: { [key: string]: string }, setError: UseFormSetError<any>) => {
	let isError = false;
	surveyQuestions.forEach((question) => {
		const conditional =
			surveyValues[`question_${question?.id}`].includes('Drugo') &&
			(!surveyValues[`question_${question?.id}_text`] || surveyValues[`question_${question?.id}_text`]?.trim() === '');
		question.answers.forEach((answer) => {
			if (answer.input && conditional) {
				const id = `question_${question?.id}_text` as keyof SurveyFormValues;
				setError(id, {
					message: 'Prosimo, vnesite vrednost.',
				});
				isError = true;
			}
		});
	});
	return isError;
};
