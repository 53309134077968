export class Endpoints {
	public static readonly Register = '/auth/register';
	public static readonly Login = '/auth/login';
	public static readonly LoginConfirm = '/auth/login-user-with-magic-link';
	public static readonly Logout = '/auth/logout';
	public static readonly Refresh = '/auth/refresh';
	public static readonly ConfirmRegistration = '/auth/sign-up/{hash}';
	public static readonly ResetPassword = '/auth/reset';
	public static readonly ConfirmReset = '/auth/reset-confirmation';
	public static readonly Profile = '/auth/profile';
	public static readonly InvoiceType = '/auth/profile/invoice-type';
	public static readonly ChangePassword = '/auth/reset-password';
	public static readonly EditProfile = '/auth/edit-profile';
	public static readonly CreateTicket = '/tickets/createTicket';
	public static readonly GetTickets = '/tickets';
	public static readonly GetCategories = '/tickets/categories';
	public static readonly SendMessage = '/tickets/createMessage';
	public static readonly SendSurvey = '/surveys/store';
	public static readonly GetUnread = '/tickets/unreadCount';
	public static readonly GetAttachment = '/tickets/attachment/:id';
	public static readonly ConfirmInterest = '/tickets/setTicketInterest';

	public static getConfirmRegistration(hash: string): string {
		return Endpoints.ConfirmRegistration.replace('{hash}', hash);
	}
}
