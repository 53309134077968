import React from 'react';
import './Title.scss';
import classNames from 'classnames';

interface TitleProps {
	modifier?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	title?: string | undefined | null;
	children?: React.ReactNode;
}

function Title({ modifier = 'h3', title, children, ...props }: TitleProps) {
	const Tag = modifier;
	return (
		<Tag className={classNames(['title', modifier && `title--${modifier}`])} {...props}>
			{title}
			{children}
		</Tag>
	);
}

export default React.memo(Title);
