import React, { Suspense, useEffect, useState } from 'react';
import Modal from '@molecules/Modal/Modal';
import Spinner from '@atoms/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './SurveyModal.scss';
import { useSendSurvey } from '@hooks/api/useSendSurvey';
import SurveyTitleScreen from '@molecules/SurveyModal/SurveyTitleScreen';
import SurveyMainScreen from '@molecules/SurveyModal/SurveyMainScreen';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { z } from 'zod';

import { useUserStore } from '@state/auth.state';
import Button from '@atoms/Button/Button';
import { validateQuestionInput } from '@utils/validation';

const surveyDefaults: { [key: string]: string } = {
	is_interested: '0',
	raffle: '',
	question_1: '',
	question_2: '',
	question_2_text: '',
	question_3: '',
	question_3_text: '',
	question_4: '',
};

export const surveySchema = z.object({
	raffle: z.string({ invalid_type_error: 'Prosimo izberite vrednost.' }),
	question_1: z.string({ invalid_type_error: 'Prosimo izberite vrednost.' }),
	question_2: z.array(z.string(), { invalid_type_error: 'Prosimo izberite vrednost.' }),
	question_2_text: z.string({ invalid_type_error: 'Prosimo izberite vrednost.' }),
	question_3: z.string({ invalid_type_error: 'Prosimo izberite vrednost.' }),
	question_3_text: z.string({ invalid_type_error: 'Prosimo izberite vrednost.' }),
	question_4: z.string({ invalid_type_error: 'Prosimo izberite vrednost.' }),
});

export type SurveyFormValues = z.infer<typeof surveySchema>;

const checksForCustomValue = (question: string, customValue: string, symbol = ',') => {
	if (customValue !== '') {
		return `${question}${symbol}${customValue}`;
	}

	return question;
};

function SurveyModal() {
	const { t } = useTranslation();
	const [user] = useUserStore((store) => [store.user]);
	const [isOpen, toggle] = useState(true);
	const [surveyStep, setSurveyStep] = useState<'intro' | 'survey' | 'thankyou'>('intro');
	const [surveyValues, setSurveyValue] = useState(surveyDefaults);
	const { sendSurvey, isLoading, data } = useSendSurvey();

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<SurveyFormValues>({
		resolver: zodResolver(surveySchema),
	});

	const onSubmit = async (isInterested = true) => {
		const inputFieldError = validateQuestionInput(surveyValues, setError);
		if (!inputFieldError) {
			sendSurvey({
				...surveyValues,
				user_id: user?.id,
				survey_id: 1,
				email: user?.email,
				is_interested: !isInterested ? '0' : '1',
				raffle: surveyValues?.raffle === 'Da' ? '1' : '0',
				question_2: checksForCustomValue(surveyValues.question_2 as string, surveyValues.question_2_text as string),
				question_3: checksForCustomValue(surveyValues.question_3 as string, surveyValues.question_3_text as string, ': '),
			});
		}
	};

	const setSurveyValues = (key: string, value: string, multiple?: boolean) => {
		if (multiple) {
			const tmpValue = String(surveyValues[key]).split(',');
			if (tmpValue[0] === '') {
				tmpValue.splice(0, 1);
			}
			if (!tmpValue.includes(String(value))) {
				tmpValue.push(String(value));
			} else {
				const tmpIndex = tmpValue.findIndex((item) => item === value);
				tmpValue.splice(tmpIndex, 1);
			}

			setSurveyValue({ ...surveyValues, [key]: tmpValue.join(',') });
			return;
		}

		setSurveyValue({ ...surveyValues, [key]: value });
	};

	const title = () => {
		switch (surveyStep) {
			case 'intro':
				return t('surveyForm.mainTitle');
			case 'survey':
				return t('surveyForm.questionsTitle');
			case 'thankyou':
				return t('surveyForm.thankYou');
			default:
				return t('surveyForm.mainTitle');
		}
	};

	useEffect(() => {
		if (data && data?.success) {
			setSurveyStep('thankyou');
		}
		document.body.classList.toggle('block-scroll', isOpen);
	}, [data, isOpen]);

	return (
		<Modal hasBackgroundClose={false} isOpen={isOpen} title={title()} onClose={() => toggle(false)}>
			{surveyStep === 'intro' && (
				<motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ duration: 0.2, delay: 0.6 }}>
					<Suspense fallback={<Spinner />}>
						<SurveyTitleScreen startSurvey={setSurveyStep} onSubmit={onSubmit} />
					</Suspense>
				</motion.div>
			)}
			{surveyStep === 'survey' && !data?.success && (
				<motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ duration: 0.2, delay: 0.6 }}>
					<Suspense fallback={<Spinner />}>
						<SurveyMainScreen
							errors={errors}
							handleSubmit={handleSubmit}
							isLoading={isLoading}
							register={register}
							setValue={setSurveyValues}
							validationSchema={surveySchema}
							values={surveyValues}
							onSubmit={onSubmit}
						/>
					</Suspense>
				</motion.div>
			)}
			{surveyStep === 'thankyou' && data?.success && (
				<motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ duration: 0.2, delay: 0.6 }}>
					<Suspense fallback={<Spinner />}>
						<div className="survey-modal__btn-container">
							<Button onClick={() => toggle(false)}>{t('surveyForm.continue')}</Button>
						</div>
					</Suspense>
				</motion.div>
			)}
		</Modal>
	);
}

export default React.memo(SurveyModal);
