import Radio from '@atoms/Radio/Radio';
import Input from '@atoms/Input/Input';
import FormError from '@atoms/FormError/FormError';
import Checkbox from '@atoms/Checkbox/Checkbox';
import React, { useState } from 'react';
import classNames from 'classnames';
import { AnswerType, prizeGame } from '@core/survey/survey-data';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldErrors } from 'react-hook-form';
import { SurveyFormValues, surveySchema } from '@molecules/SurveyModal/SurveyModal';

interface SurveyAnswersBaseProps {
	register: UseFormRegister<SurveyFormValues>;
	errors: FieldErrors;
	validationSchema: typeof surveySchema;
	value: string;
	setValue: (key: string, value: string, multiple?: boolean) => void;
}
export interface SurveyAnswersProps extends SurveyAnswersBaseProps {
	id: number | string;
	answers: Array<AnswerType>;
}

export function SurveyRadio({ id, answers, register, errors, validationSchema, value, setValue }: SurveyAnswersProps) {
	return (
		<>
			<div>
				{answers.map((answer) => (
					<div key={`question-${id}-answer-${answer?.id}`}>
						<Radio
							checked={answer?.text === value}
							errors={errors}
							id={`question-${id}-answer-${answer?.id}`}
							label={answer?.text}
							modifier="red"
							name={typeof id !== 'string' ? `question_${id}` : id}
							register={register}
							validationSchema={validationSchema}
							value={answer?.text}
							onChange={() => setValue(typeof id !== 'string' ? `question_${id}` : id, answer.text)}
						/>
						{answer?.input && (
							<Input
								additionalClass="survey-modal__question-input"
								errors={errors}
								isDisabled={!value.includes(answer?.text)}
								name={`question_${id}_text`}
								placeholder="Polje za vpis teksta"
								register={register}
								type="textarea"
								validationSchema={validationSchema}
								onChange={(e) => setValue(`question_${id}_text`, e.target.value)}
							/>
						)}
					</div>
				))}
			</div>
			<FormError isError={!!errors[`question_${id}`]}>{errors[`question_${id}`]?.message as React.ReactNode}</FormError>
		</>
	);
}

export function SurveyCheckbox({ id, answers, register, errors, validationSchema, value, setValue }: SurveyAnswersProps) {
	return (
		<>
			<div>
				{answers.map((answer) => (
					<div key={`question-${id}-answer-${answer?.id}`}>
						<Checkbox
							checked={value.includes(answer?.text)}
							errors={errors}
							id={`question-${id}-answer-${answer?.id}`}
							label={answer?.text}
							name={`question_${id}`}
							register={register}
							validationSchema={validationSchema}
							onChange={() => setValue(`question_${id}`, answer.text, true)}
						/>
						{answer?.input && (
							<Input
								errors={errors}
								isDisabled={!value.includes(answer?.text)}
								name={`question_${id}_text`}
								placeholder="Polje za vpis teksta"
								register={register}
								type="textarea"
								validationSchema={validationSchema}
								onChange={(e) => setValue(`question_${id}_text`, e.target.value)}
							/>
						)}
					</div>
				))}
			</div>

			<FormError isError={!!errors[`question_${id}`]}>{errors[`question_${id}`]?.message as React.ReactNode}</FormError>
		</>
	);
}

export function SurveyScale({ id, answers, register, errors, validationSchema, value, setValue }: SurveyAnswersProps) {
	const [hoverIndex, setHoverIndex] = useState<number | null>(null);

	return (
		<>
			<div className="survey-modal__question--stars">
				{answers.map((answer, index) => (
					<Radio
						key={`question-${id}-answer-${answer?.id}`}
						checked={answer?.text === value}
						errors={errors}
						id={`question-${id}-answer-${answer?.id}`}
						label={String(answer?.id)}
						modifier="star"
						name={`question_${id}`}
						register={register}
						validationSchema={validationSchema}
						additionalClass={classNames([
							hoverIndex && index + 1 <= hoverIndex && 'radio--star-hover',
							!hoverIndex && Number(answer?.text) <= Number(value) && 'radio--star-selected',
						])}
						onChange={() => setValue(`question_${id}`, answer.text)}
						onMouseLeave={() => setHoverIndex(null)}
						onMouseEnter={() => {
							setHoverIndex(index + 1);
						}}
					/>
				))}
			</div>

			<FormError isError={!!errors[`question_${id}`]}>{errors[`question_${id}`]?.message as React.ReactNode}</FormError>
		</>
	);
}

export function SurveyPrizeGame({ register, errors, validationSchema, value, setValue }: SurveyAnswersBaseProps) {
	return (
		<>
			<div key={`question-id-${prizeGame.id}`} className="survey-modal__question">
				{prizeGame?.text && <h3 className="survey-modal__question-title">{prizeGame.text}</h3>}
				<div className="survey-modal__question-subtitle" dangerouslySetInnerHTML={{ __html: prizeGame.subtext }} />
				<SurveyRadio
					answers={prizeGame.answers}
					errors={errors}
					id={prizeGame.id}
					register={register}
					setValue={setValue}
					validationSchema={validationSchema}
					value={value}
				/>
			</div>

			<FormError isError={!!errors.raffle}>{errors.raffle?.message as React.ReactNode}</FormError>
		</>
	);
}
