import React, { useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import Icons from '@/components/atoms/Icons/Icons';
import './Modal.scss';
import Title from '@/components/atoms/Title/Title';

interface ModalProps {
	title?: string | undefined;
	isOpen: boolean;
	hasClose?: boolean;
	hasBackgroundClose?: boolean;
	onClose: () => void;
	children: React.ReactNode;
	additionalClass?: string;
}

function Modal({ additionalClass, title, isOpen, children, hasClose = true, hasBackgroundClose = true, onClose }: ModalProps) {
	const onKeyPress = useCallback(
		(event?: KeyboardEvent) => {
			const isNotClose = event?.type === 'keydown' && event.key !== 'Escape';

			if (hasClose && !isNotClose) {
				onClose();
			}
		},
		[hasClose, onClose],
	);

	useEffect(() => {
		document.addEventListener('keydown', onKeyPress);

		return () => {
			document.removeEventListener('keydown', onKeyPress);
		};
	}, [onKeyPress]);

	return (
		<AnimatePresence>
			{isOpen && (
				<motion.div layout className={classNames(['modal', isOpen && 'is-open-modal', additionalClass || ''])}>
					<motion.div
						animate={{ opacity: 1 }}
						className="modal__overlay : covered"
						exit={{ opacity: 0 }}
						initial={{ opacity: 0 }}
						transition={{ duration: 0.2 }}
						onClick={() => {
							hasClose && hasBackgroundClose ? onKeyPress() : '';
						}}
					/>

					<motion.div
						layout
						animate={{ scale: 1, opacity: 1 }}
						className="modal__inner-wrapper"
						exit={{ scale: 0.8, opacity: 0 }}
						initial={{ scale: 0.8, opacity: 0 }}
						transition={{ duration: 0.3, ease: [0.17, 0.67, 0.53, 1.19] }}
						onClick={(e) => e.stopPropagation()}
					>
						{hasClose && (
							<button className="modal__close : d-flex-cc" type="button" onClick={() => onKeyPress()}>
								<Icons modifier="close" />
							</button>
						)}

						<motion.div
							animate={{ opacity: 1 }}
							className="modal__inner"
							initial={{ opacity: 0 }}
							transition={{ duration: 0.2, delay: 0.2 }}
						>
							{!!title && (
								<motion.div
									animate={{ opacity: 1 }}
									className="modal__header"
									initial={{ opacity: 0 }}
									transition={{ duration: 0.2, delay: 0.3 }}
								>
									<Title modifier="h4" title={title} />
								</motion.div>
							)}

							<motion.div
								layout
								animate={{ opacity: 1 }}
								className="modal__body"
								initial={{ opacity: 0 }}
								transition={{ duration: 0.2, delay: 0.4 }}
							>
								{children}
							</motion.div>
						</motion.div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}

export default React.memo(Modal);
