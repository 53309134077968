import React from 'react';
import { useTranslation } from 'react-i18next';
import './SurveyModal.scss';
import Button from '@atoms/Button/Button';
import { introText } from '@core/survey/survey-data';

interface SurveyTitleScreenProps {
	startSurvey: (value: 'intro' | 'survey' | 'thankyou') => void;
	onSubmit: (isInterested?: boolean) => void;
}

function SurveyTitleScreen({ startSurvey, onSubmit }: SurveyTitleScreenProps) {
	const { t } = useTranslation();

	const notInterested = () => {
		onSubmit(false);
	};

	return (
		<div className="survey-modal">
			<div dangerouslySetInnerHTML={{ __html: introText }} />
			<div className="survey-modal__btn-container">
				<Button buttonType="small" onClick={() => startSurvey('survey')}>
					{t('surveyForm.start')}
				</Button>
				<button className="mod-link mod-link--without-arrow" onClick={() => notInterested()}>
					{t('surveyForm.notInterested')}
				</button>
			</div>
		</div>
	);
}

export default React.memo(SurveyTitleScreen);
