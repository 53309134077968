export const introText =
	'Izpolnite kratko anketo in <b>sodelujte v nagradnem žrebanju za praktične nagrade.</b> Pogoje in pravila nagradne igre najdete na <a target="_blank" class="mod-link mod-link--without-arrow" href="https://rtvslo.si">povezavi.</a>';

export interface AnswerType {
	id: number;
	text: string;
	input?: boolean;
}

export interface QuestionType {
	id: number;
	text: string;
	subtext?: string;
	type: string;
	answers: Array<AnswerType>;
}

export const surveyQuestions: Array<QuestionType> = [
	{
		id: 1,
		text: 'V portalu Moj Butan plin lahko sedaj pošljete sporočilo naši ekipi. Je ta funkcija za vas uporabna?',
		type: 'radio',
		answers: [
			{
				id: 1,
				text: 'Da',
			},
			{
				id: 2,
				text: 'Ne',
			},
		],
	},
	{
		id: 2,
		text: 'Katere funkcionalnosti bi želeli vključiti v portal Moj Butan plin?',
		type: 'checkbox',
		answers: [
			{
				id: 1,
				text: 'Naročilo plina z obvestilom o predvideni dostavi.',
			},
			{
				id: 2,
				text: 'Integrirano plačilo računa v portalu (kartično plačilo).',
			},
			{
				id: 3,
				text: 'Pregled pogodbe (PDF datoteka).',
			},
			{
				id: 4,
				text: 'Naročilo jeklenke',
			},
			{
				id: 5,
				text: 'Naročilo servisa plinohrama',
			},
			{
				id: 6,
				text: 'Drugo: (vpišite)',
				input: true,
			},
		],
	},
	{
		id: 3,
		text: 'Kaj vas moti v portalu Moj Butan plin?',
		type: 'radio',
		answers: [
			{
				id: 1,
				text: 'Nič',
			},
			{
				id: 2,
				text: 'Drugo: (vpišite)',
				input: true,
			},
		],
	},
	{
		id: 4,
		text: 'Kako verjetno je, da bi uporabo portala Moj Butan plin priporočili tudi drugim uporabnikom?',
		subtext: 'Izberite 1 - 5 zvezdic (1 - ne bi priporočil, 5 - bi priporočil)',
		type: 'scale',
		answers: [
			{
				id: 1,
				text: '1',
			},
			{
				id: 2,
				text: '2',
			},
			{
				id: 3,
				text: '3',
			},
			{
				id: 4,
				text: '4',
			},
			{
				id: 5,
				text: '5',
			},
		],
	},
];

export const prizeGame = {
	id: 'raffle',
	text: 'Bi želeli sodelovati v nagradni igri?',
	subtext:
		'S tem se tudi strinjate s <a target="_blank" class="mod-link mod-link--without-arrow" href="https://rtvslo.si">pravili in pogoji nagradne igre.</a>',
	type: 'radio',
	answers: [
		{
			id: 1,
			text: 'Da',
			value: true,
		},
		{
			id: 2,
			text: 'Ne',
			value: false,
		},
	],
};
