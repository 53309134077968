import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@hooks/useRequest';
import { toast } from 'sonner';
import { Endpoints } from '@core/endpoints';
import { QueryKeyType } from '@core/enums/query-key.enum';

interface SubmitSurveyValues {}

export const useSendSurvey = () => {
	const { t } = useTranslation();
	const { request } = useRequest();

	const { mutate, error, isLoading, data } = useMutation(
		[QueryKeyType.SEND_SURVEY, 'user-id'],
		async (survey: SubmitSurveyValues) => {
			const response = await request({
				returnAllData: true,
				url: Endpoints.SendSurvey,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				data: survey,
			});

			return response.data;
		},
		{
			onError: () => {
				toast.error(t('messagesPage.ticketFailed'));
			},
		},
	);

	return {
		data,
		sendSurvey: mutate,
		error,
		isLoading,
	};
};
