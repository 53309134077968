import React, { Fragment, HTMLInputTypeAttribute, useCallback, useState } from 'react';
import './Input.scss';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Icons from '../Icons/Icons';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	isDisabled?: boolean;
	label?: string | undefined | null;
	placeholder?: any;
	subtext?: string | undefined | null;
	register: any;
	errors: any;
	validationSchema?: any;
	additionalClass?: string;
}

function Input({
	name,
	label,
	register,
	errors,
	subtext,
	isDisabled = false,
	required,
	validationSchema,
	type,
	additionalClass,
	...props
}: InputProps) {
	const [inputType, setInputType] = useState<HTMLInputTypeAttribute | undefined>(type);

	const togglePassword = useCallback(() => {
		setInputType(inputType === 'text' ? 'password' : 'text');
	}, [inputType]);

	return (
		<motion.div
			className={classNames([
				'form-control-input',
				errors[name] && 'is-validation-failed',
				type === 'password' && 'form-control-input--password',
				isDisabled && 'is-disabled',
				additionalClass,
			])}
		>
			{label && (
				<label className="form-control-input__label : text--14 : text--md" htmlFor={name}>
					{label}
				</label>
			)}

			<div className="form-control-input__inner">
				{type === 'textarea' ? (
					<textarea
						className="form-control-input__field"
						id={name}
						name={name}
						tabIndex={isDisabled ? -1 : 0}
						{...register(name, validationSchema)}
						{...props}
					/>
				) : (
					<>
						<input
							className="form-control-input__field"
							id={name}
							name={name}
							tabIndex={isDisabled ? -1 : 0}
							type={inputType}
							{...register(name, validationSchema)}
							{...props}
						/>

						{type === 'password' && (
							<button className="form-control-input__password-trigger" type="button" onClick={togglePassword}>
								{type === inputType ? <Icons modifier="eye-open" /> : <Icons modifier="eye-close" />}
							</button>
						)}
					</>
				)}
			</div>

			<AnimatePresence>
				{errors && (errors[name]?.type || errors[name]) && (
					<motion.div
						animate={{ height: 'auto', overflow: 'visible' }}
						className="error"
						exit={{ height: 0, overflow: 'hidden' }}
						initial={{ height: 0, overflow: 'hidden' }}
						transition={{ duration: 0.2 }}
					>
						<motion.div animate={{ opacity: 1 }} className="error__inner" initial={{ opacity: 0 }} transition={{ delay: 0.2 }}>
							{errors[name]?.message}
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>

			{!!subtext && <small className="form-control-input__subtext">{subtext}</small>}
		</motion.div>
	);
}

export default React.memo(Input);
