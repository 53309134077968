import React from 'react';
import { useTranslation } from 'react-i18next';
import './SurveyModal.scss';
import Button from '@atoms/Button/Button';
import { FieldErrors, UseFormHandleSubmit } from 'react-hook-form';
import { AnswerType, surveyQuestions } from '@core/survey/survey-data';
import { SurveyCheckbox, SurveyPrizeGame, SurveyRadio, SurveyScale } from '@molecules/SurveyModal/SurveyAnswers';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { SurveyFormValues, surveySchema } from '@molecules/SurveyModal/SurveyModal';

interface SurveyMainScreenProps {
	isLoading: boolean;
	register: UseFormRegister<SurveyFormValues>;
	errors: FieldErrors;
	validationSchema: typeof surveySchema;
	setValue: (key: string, value: string, multiple?: boolean) => void;
	values: { [key: string]: string };
	handleSubmit: UseFormHandleSubmit<any>;
	onSubmit: (isInterested?: boolean) => void;
}

function SurveyMainScreen({
	isLoading,
	register,
	errors,
	validationSchema,
	setValue,
	values,
	handleSubmit,
	onSubmit,
}: SurveyMainScreenProps) {
	const { t } = useTranslation();

	const getAnswersComponent = (type: string, answers: Array<AnswerType>, id: number) => {
		switch (type) {
			case 'radio':
				return (
					<SurveyRadio
						key={`answers-question-${id}`}
						answers={answers}
						errors={errors}
						id={id}
						register={register}
						setValue={setValue}
						validationSchema={validationSchema}
						value={values[`question_${id}`]}
					/>
				);
			case 'checkbox':
				return (
					<SurveyCheckbox
						key={`answers-question-${id}`}
						answers={answers}
						errors={errors}
						id={id}
						register={register}
						setValue={setValue}
						validationSchema={validationSchema}
						value={values[`question_${id}`]}
					/>
				);
			case 'scale':
				return (
					<SurveyScale
						key={`answers-question-${id}`}
						answers={answers}
						errors={errors}
						id={id}
						register={register}
						setValue={setValue}
						validationSchema={validationSchema}
						value={values[`question_${id}`]}
					/>
				);
			default:
				return <span>ERROR</span>;
		}
	};

	return (
		<div className="survey-modal">
			<form className="survey-modal__form" onSubmit={handleSubmit(onSubmit)}>
				{surveyQuestions.map((question) => (
					<div key={`question-id-${question?.id}`} className="survey-modal__question">
						{question?.text && <h3 className="survey-modal__question-title">{question.text}</h3>}
						{question?.subtext && (
							<div className="survey-modal__question-subtitle" dangerouslySetInnerHTML={{ __html: question?.subtext }} />
						)}
						<div>{getAnswersComponent(question?.type, question?.answers, question?.id)}</div>
					</div>
				))}
				<div className="survey-modal__game-text">
					<span className="survey-modal__border survey-modal__border-left" />
					<span className="survey-modal__game-text-title">{t('surveyForm.prizeGame')}</span>
					<span className="survey-modal__border survey-modal__border-right" />
				</div>
				<SurveyPrizeGame
					errors={errors}
					register={register}
					setValue={setValue}
					validationSchema={validationSchema}
					value={values.raffle}
				/>
				<div className="survey-modal__btn-container">
					<Button buttonType="small" isLoading={isLoading}>
						{t('surveyForm.submit')}
					</Button>
				</div>
			</form>
		</div>
	);
}

export default React.memo(SurveyMainScreen);
